import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DynamicImg from "../components/imageDynamic"

const IndexPage = () => (
  <Layout>
    <SEO title="News" keywords={[`gatsby`, `application`, `react`]} />
    <h1>jbr five</h1>

    <div style={{ maxWidth: `642px`, marginBottom: `1.45rem` }}>
      <DynamicImg src="Band.png" />
    </div>

    <p>
      Die Gruppe <span style={{ fontWeight: "bold" }}> "jbr five"</span> setzt sich aus 5 Musikern zusammen mit den
      Instrumenten Klarinette / Saxofon, Gitarre, Bass, Klavier, Schlagzeug und Gesang. Wir spielen hauptsächlich Jazz
      und Swing der 30-und 40-er Jahre, Blues, Rock, gelegentlich auch Tanzmusik.
    </p>
    <p>
      Beispiele aus unserem Repertoire: "Wild Cat Blues" oder "Petit Fleur“, "Tequila", auch "Buona Sera Signorina" aus
      der Sparte Tanzmusik, aus dem Rock-Bereich "Jailhouse Rock", „Summer in the City“, “Blue Suede Shoes“. Auf Wunsch
      auch mal den "Schneewalzer".
    </p>
    <p>
      Wir sind im nördlichen Hamburg zu Hause und treten seit vielen Jahren vorwiegend im Raum Hamburg bei öffentlichen
      oder privaten Veranstaltungen auf.
    </p>
    <p style={{ fontWeight: "bold" }}>Hier können Sie mit uns Kontakt aufnehmen: contact@jbr-five.de</p>
  </Layout>
)

export default IndexPage
